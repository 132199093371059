import React from 'react'
import Layout from '../../components/layout'
import FullWidth from '../../components/fullWidth'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/seo'
import { TalkWithFoundersCta } from '../../components/SignUp'
import classnames from 'classnames'
import shareImage from '../../images/integrations/stripe/share-image.png'
import logo from '../../images/logos/stripe.svg'

import * as heroStyle from '../../components/webreport/ReportHero.module.css'
import * as style from './stripe.module.css'

const title = () => process.env.GATSBY_APP_URL.replace(/^https:\/\//i, '')

const Integrations = () => (
  <Layout nakedBar trialCta>
    <Seo
      title={`${title()} - Stripe integration`}
      description={
        'Probe + Stripe integration. Effortless business analytics using Stripe.'
      }
      keywords={['integration', 'stripe', 'saas', 'mrr', 'bi', 'analytics']}
      twitterImg={shareImage}
      mainpageImg={shareImage}
    />
    <FullWidth>
      <div className={heroStyle.heroContent}>
        <div className={classnames(heroStyle.heroCopy, style.heroCopyWidth)}>
          <h1 className={heroStyle.title}>Business analytics for Stripe</h1>
          <p className={heroStyle.subtitle}>
            Connect Stripe and get insights into 50+ business metrics including
            advanced analytics like cohorts, segmentation and cost-related
            metrics.
          </p>
          <p className={heroStyle.subtitle}>
            With high-touch onboarding we make sure that you trust the data you
            see.
          </p>
          <div>
            <TalkWithFoundersCta />
          </div>
        </div>
        <div className={classnames(heroStyle.heroImage, style.heroImageWidth)}>
          <div className={style.logoWrapper}>
            <img src={logo} width="200px" alt="Stripe logo" />
          </div>
        </div>
      </div>
      <Testimonial
        person="Valerie Kirk"
        title="Director of Business Operations"
        url="https://castle.io"
      >
        "They were able to take our historical data as well, so we could start
        the clock several years earlier, which is essential in tracking trends
        over time. <br />
        This was particularly critical because at one point we were maintaining
        two billing systems. There is no way Stripe would have been able to
        incorporate our historical information to get the complete picture that
        we needed."
      </Testimonial>
    </FullWidth>
  </Layout>
)

export default Integrations
